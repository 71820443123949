// This file is generated via scripts/build-static-config.js
  // Do not modify directly as changes will be overwritten in CI

  export const CONFIGURATION = {
    country: 'ae',
    brand: 'bk',
    env: 'prod',
    graphqlEnv: 'prod',
    graphqlGatewayEnv: '',
    sanityEnv: 'prod',
    platform: 'web',
    commitRef: '01136ee5ab166b5ce469cd1922f9d9b269ec87a0',
    releaseTagRef: 'b79c0cd',
    releaseTimestamp: '1737706223',
    sanityDataset: '',
    sanityProjectId: 'czqk28jt',
    appFlowBuildId: '',
    clickTrackingDomain: '',
    adyen: {
      clientKey: '',
      environment: '',
      urlPrefix: '',
      ideal: {
        idealBanks: [],
      }
    },
    apiKeys: {
      amplitude: '92fc1ef9e2babd87a251c85112afdcc1',
      branch: '',
      braze: 'dfa5f961-2957-454f-ae13-d0b0c5fb391e',
      brazeCluster: 'sdk.fra-02.braze.eu',
      bringg: '',
      googleMaps: 'AIzaSyA1K-0xdv7Zo1yFmhkNxNaXfOzApvXdV4c',
      tomtom: '',
      launchDarkly: '65bbd05ac419d90fe2026759',
      launchDarklyMobileKey: 'mob-e83c8453-84da-4c98-aa74-92fe6159681a',
      mParticle: 'eu1-d836ae6962103549b46f88cc239aecaa',
      amplitudeCdpKey: '',
      amplitudeCdpRegion: '',
    },
    apple: {
      merchantId: 'merchant.ae.burgerking',
      migrationMerchantId: '',
      paymentsNetworks: ['AmEx','MasterCard','Visa','Discover'],
      prepaidMerchantId: 'pk_c352yiwlzuwxwsejxiban5jl34o',
      migrationPrepaidMerchantId: '',
      socialLogin: {
        clientId: '',
        redirectURI: '',
      },
    },
    branch: {
      domains: []
    },
    checkoutDotCom: {
      publicKey: 'pk_c352yiwlzuwxwsejxiban5jl34o',
    },
    facebook: {
      socialLogin: {
        appId: '',
        redirectURI: '',
      },
    },
    aws: {
      gqlApiUrl: 'https://euc1-prod-bk.rbictg.com/graphql',
      gqlGatewayApiUrl: 'https://euc1-prod-bk-gateway.rbictg.com/graphql',
      region: 'eu-central-1',
      userPoolClientId: '62ev1gv53cp9bckrt5gdinrqmj',
      userPoolId: 'eu-central-1_dwCOsnxKY',
    },
    bringg: {
      region: '',
    },
    datadog: {
      logs: {
        clientToken: 'pub5560d3ea0973282cab306c73833e35d8',
        dataCenter: 'datadoghq.eu',
      },
      rum: {
        applicationId: 'd2ad1579-fd2d-4500-a5d1-baa39289ebe4',
        clientToken: 'pub5560d3ea0973282cab306c73833e35d8',
        dataCenter: 'datadoghq.eu',
      },
    },
    firstData: {
      corporateStoreId: '',
    },
    forter: {
      siteId: 'idNotSet',
    },
    google: {
      paymentsEnvironment: 'prod',
      paymentsGateway: 'checkoutltd',
      marketPaymentsGatewayMerchantId: 'pk_c352yiwlzuwxwsejxiban5jl34o',
      paymentsGatewayMerchantId: {
        ca: '',
        us: '',
        ch: '',
      },
      migrationPaymentsGateway: '',
      migrationPaymentsGatewayMerchantId: {
        ca: '',
        us: '',
        ch: '',
      },
      paymentsNetworks: ['AMEX','MASTERCARD','VISA','DISCOVER','JCB'],
      prepaidPaymentsGateway: '',
      prepaidPaymentsGatewayMerchantId: {
        ca: '',
        us: '',
        ch: '',
      },
      migrationPrepaidPaymentsGateway: '',
      migrationPrepaidPaymentsGatewayMerchantId: {
        ca: '',
        us: '',
        ch: '',
      },
      socialLogin: {
        clientId: '',
        redirectURI: '',
      },
    },
    googleAdManager: {
      campaignId: '',
    },
    googleTagManager: {
      containerId: '',
    },
    oneTrust: {
      cookieCompliance: {
        consentCategoryBranch: '',
        consentCategoryBraze: '',
        consentCategoryMParticle: '',
        globalConsentState: '',
        scriptUrl: '',
        scriptId: '',
      },
      deleteAccountUrl: '',
    },
    pypestream: {
      scriptUrl: '',
      appId: '',
    },
    restaurants: {
      displayEnv: 'prod',
      validMobileOrderingEnvs: ['alpha','beta','live'],
    },
    urls: {
      braze: 'https://customer.iad-03.braze.com/api/v3',
      webBaseUrl: '',
    },
    tokenEx: {
      publicKey: '',
    },
    vrPayment: {
      braintreeTokenizationKey: '',
    },
    orbital: {
      baseUrl: '',
      subscriberId: '',
      pieFormat: '',
      mode: '',
    },
    timeFormat: '',
    dateFormat: 'MM/dd/yyyy',
    addressFormat: 'name_number',
    maxCartQuantity: 9,
    appStoreDeeplink: 'https://prod-bk-web.ae.rbi.tools/update',
    iosDeepLink: '',
    androidDeepLink: '',
    cookieBanners: {
      cookieBanner:'oneTrust',
      scriptId: '',
      oneTrust: {
        cookieCompliance: {
          consentCategoryBranch: '',
          consentCategoryBraze: 'C0002',
          consentCategoryMParticle: 'C0001',
          consentCategoryDdRum: '',
          globalConsentState: 'Opt-In',
          scriptUrl: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
          scriptId: '018fa5b7-d6c3-702d-94ec-b657be321d08',
        },
      },
    }
  };